import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "@igloonet-web/shared-ui/themes/igloonet"
import { ContactFormJobs, Link, Slider } from "@igloonet-web/shared-ui"

import {
  Accordion,
  JobHeader,
  JobIntroduction,
  JobSection,
  JobWrapper,
} from "../../components"

import ProfileKepi from "../../images/team/profile/kepi.jpg"
import ProfileBara from "../../images/team/profile/bara.jpg"
import ProfileWicki from "../../images/team/profile/wicki.jpg"
import JobDetailBody from "../../components/jobs/job-detail-body"
import JobDetailContent from "../../components/jobs/job-detail-content"
import { EN_BENEFITS } from "../../model/en-benefits"
import BenefitPenguin from "../../images/jobs/benefit_penguin.svg"
// import PositionStamp from "../../components/position-stamp"

const EnLinuxAdmin: React.FC = () => {
  const personSlider = [
    {
      id: 1,
      imageSrc: ProfileBara,
      name: "Bára Michalčíková",
      position: "administrativa",
    },
    {
      id: 2,
      imageSrc: ProfileKepi,
      name: "Kepi",
      position: "Vedoucí hostingu & devops",
    },
    {
      id: 3,
      imageSrc: ProfileWicki,
      name: "Martin Taraba",
      position: "podpora na hostingu",
    },
  ]

  return (
    <ThemeProvider theme={theme}>
      <JobWrapper>
        <JobHeader />

        <JobDetailBody>
          <Link
            className="text-center text-lg-right px-3 d-block"
            to="/jobs/linux-admin-ka"
          >
            Přepnout do češtiny
          </Link>
          {/* <PositionStamp paused /> */}
          <JobDetailContent className="px-2 px-lg-0">
            <JobIntroduction title="Linux admin">
              <p>
                <strong>
                  Hi, we are <Link to="/">igloonet</Link>
                </strong>{" "}
                and we have been in hosting & devops business for eighteen years
                now.
              </p>
              <p>
                <strong>TL;DR:</strong> We are looking for a handy full-time
                partner for small team in Brno. Someone who is responsible,
                reliable, can handle anything, and can help us with both routine
                admin worries and further development.
              </p>
              <p>
                <strong>Note from the start:</strong> We choose our colleagues
                carefully, but it&apos;s the attitude and character that
                matters. We can build knowledge and experience together.
              </p>
            </JobIntroduction>

            <JobSection title="Why is this position important?">
              <ul>
                <li>
                  You&apos;re the reason people get{" "}
                  <a
                    href="https://www.snowboard-zezula.cz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    a new board
                  </a>
                  ,{" "}
                  <a
                    href="https://www.planetaher.cz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    a board game
                  </a>{" "}
                  for the weekend,{" "}
                  <a
                    href="https://www.doktorkladivo.cz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    a decent tool
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.e-pneumatiky.cz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    tyres
                  </a>{" "}
                  for the summer.
                </li>
                <li>
                  Our customers can do a lot of cool stuff and we want them to
                  be able to focus on that. They won&apos;t have to worry about
                  the technical operation of their projects with you on board.
                </li>
                <li>
                  All of us at igloo use a lot of internal systems that have to
                  work reliably.
                </li>
                <li>
                  Servers, virtuals, containers... they all need a lot of love.
                </li>
              </ul>
            </JobSection>

            <JobSection title="Is the job for you?">
              <p>
                <strong>Your daily bread will be</strong>
              </p>
              <p>
                <cite>
                  I&apos;d better clarify: this is a finish line, not something
                  you have to know when you start. But at the same time, this is
                  what you can look forward to encountering with us. We actually
                  use everything listed in day to day operations.
                </cite>
              </p>
              <ul>
                <li>Ansible role playing and Terraforming.</li>
                <li>Exploring and deploying new technologies.</li>
                <li>
                  Programming and scripting in <em>Python</em>, <em>Bash</em>,{" "}
                  <em></em>AWK, <em>jq</em>…
                </li>
                <li>
                  Documenting practices and developing our internal{" "}
                  <em>Org-mode</em> wiki and help.
                </li>
                <li>
                  Configuring, testing and optimizing <em>Apache</em>,{" "}
                  <em>PHP</em>, <em>Nginx</em>, <em>MariaDB</em>, <em>Redis</em>
                  , <em>RabbitMQ</em>, <em>InnoDB cluster</em>…
                </li>
                <li>
                  Maintaining our private Cloud on <em>OpenNebula</em>,
                  debugging <em>Ceph</em>, <em>KVM</em>, <em>LXC</em>{" "}
                  containers...
                </li>
                <li>
                  Modifications and optimizations of internal systems (
                  <em>Icinga2</em>, <em>ELK</em>, <em>InfluxDB</em>,{" "}
                  <em>Grafana</em>, <em>Gitlab CI</em>…).
                </li>
                <li>Updates, updates and more updates.</li>
                <li>Log tracking, troubleshooting.</li>
              </ul>
              <p>
                <strong>What we care about</strong>
              </p>
              <ul>
                <li>
                  <strong>You are reliable. No excuses.</strong>
                </li>
                <li>You know Linux well, ideally Debian.</li>
                <li>
                  You can learn new things quickly. And you&apos;ll figure out
                  why even faster.
                </li>
                <li>
                  You can program. It&apos;s not about the language, it&apos;s
                  about the mindset. You know data types and how to work with
                  them.
                </li>
                <li>
                  You can solve a problem. Explore, analyze, write, solve, and
                  document. Not just the one you already know.
                </li>
                <li>
                  You can write documentation without problems. In a way that
                  others can understand. We don&apos;t have to push you.
                </li>
                <li>
                  You can debug problems. If something doesn&apos;t work, you
                  can quickly narrow down a potential problem, use the
                  appropriate tool, and get a solution or hotfix.
                </li>
                <li>
                  You are not stuck in the past. You have been improving your
                  knowledge steadily.
                </li>
                <li>
                  You know that security is important. You don&apos;t compromise
                  on it.
                </li>
                <li>
                  Czech language: it would be great if you know some, but
                  intermediate English is enough.
                </li>
              </ul>
              <p>
                <strong>
                  What we don&apos;t care about, on the other hand.
                </strong>
              </p>
              <ul>
                <li>
                  How old you are, whether you pee standing up or sitting down,
                  how you dress, where are you from, who you want to live with
                  or who your parents are.
                </li>
                <li>
                  Your education. It really doesn&apos;t matter to us. Not your
                  high school diploma, not your degrees, not your certificates
                  or diplomas (except for <i>BSc., SSc.</i> of course).
                </li>
                <li>Lack of formal experience or glowing references.</li>
                <li>
                  The fact that you meet everything in the previous paragraph.
                  It is enough if you trust yourself to master those with our
                  help in time.
                </li>
              </ul>
              <p>
                <strong>Our dreams</strong>
              </p>
              <ul>
                <li>
                  You have a good relationship with Free Software. When you find
                  a bug, you report it and help with the solution.
                </li>
                <li>
                  You used to be busy programming in{" "}
                  <code>((Python|Ruby|Haskell|elisp|.+) )+</code>.
                </li>
                <li>
                  You can work with the customer to come up with a suitable
                  solution.
                </li>
                <li>
                  You know{" "}
                  <code>
                    ((L[AN]MP)|Redis|(Postgre|My)SQL|(Maria|Mongo)DB|rsyslog|Icinga|HAProxy|Xen|kvm|Docker)+
                  </code>
                  .
                </li>
                <li>
                  You are{" "}
                  <a
                    href="//github.com/emacs-evil/evil/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    evil
                  </a>{" "}
                  in (
                  <a href="//spacemacs.org/" target="_blank" rel="noreferrer">
                    spac
                  </a>
                  )?
                  <a
                    href="//gnu.org/software/emacs/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    emacs
                  </a>
                  .
                </li>
                <li>
                  You can&apos;t live without{" "}
                  <a href="//orgmode.org/" target="_blank" rel="noreferrer">
                    Org-mode
                  </a>{" "}
                </li>
                <li>
                  You enjoy sharing your knowledge, lecturing or{" "}
                  <Link to="/blog/">blogging</Link>.
                </li>
              </ul>
            </JobSection>

            <JobSection title="What will you learn with us?">
              <p>
                There will be plenty, no matter how much you know now. We stick
                to the tried and tested stuff, but we&apos;re always looking for
                ways to improve. At the same time, we&apos;ve got a lot of
                internal mini-projects underway to push ourselves further.
              </p>
              <p>
                Just a few things you&apos;ll probably come across in the near
                future:
              </p>
              <ul>
                <li>Automate server management via Ansible.</li>
                <li>Create monitoring checks for Icinga.</li>
                <li>Work with spacemacs, Org-mode and the tools around it.</li>
                <li>
                  CEPH, KVM, OpenNebula, ZFS, Btrfs, GlusterFS, Open vSwitch,
                  InnoDB cluster, MongoDB, RabbitMQ, Redis and Haproxy.
                </li>
                <li>Lots of web hosting, domain and TLS certificate stuff.</li>
              </ul>
            </JobSection>

            <JobSection title="What's in store for you?">
              <p>
                We&apos;re not looking for a superman with abnormal abilities.
                We want to work with someone who enjoys Linux and will gradually
                get into everything we do here. We want a lazy person who
                automates everything.
              </p>
              <p>
                We take care of three main areas: web hosting, virtual server
                infrastructure, and customized solutions for us (hosting); our
                developers, our marketeers; and our customers&apos; projects.
              </p>
              <p>
                Most of us go to the office Monday through Wednesday and work
                from home Thursday and Friday. If more remote work is better for
                you, we can work it out. Working hours are by mutual agreement.
                We have Debian on our servers, but you can have whatever you
                want on your machine.
              </p>
              <p>
                We&apos;ll gradually introduce you to our practices, teach you
                parts you don&apos;t know and learn from you those we don&apos;t
                know. And of course get right to work.
              </p>
              <p>
                We operate in a very open way, the direction of our department
                is devised by all of us together, as well as the way we work. We
                use Redmine to track tasks and Kanban to organize work. We like
                free software, we try to publish our customizations, plugins and
                tools for others to use.
              </p>
              <p>
                Even though server administration is a lot about routine, we try
                to give everyone a variety of work and find enough space to do
                the things they enjoy more.
              </p>
              <p>
                There&apos;s a lot more to it, best to keep it to the interview.
              </p>
            </JobSection>

            <Accordion
              modal
              en
              mainTitle="Benefits"
              data={EN_BENEFITS}
              mainImage={BenefitPenguin}
            />
          </JobDetailContent>

          <Slider title={"Who will you be working with?"} data={personSlider} />

          <ContactFormJobs
            heading="Don't postpone it, write to Kepi"
            modal
            lang="en"
          />
        </JobDetailBody>
      </JobWrapper>
    </ThemeProvider>
  )
}

export default EnLinuxAdmin
