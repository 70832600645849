import React from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
}

const Wrapper = styled.div`
  position: relative;
`
const JobDetailBody: React.FC<Props> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
)

export default JobDetailBody
