import React from "react"
import styled from "styled-components"

type Props = {
  children: React.ReactNode
  className: string
}

const Wrapper = styled.div`
  display: block;
  margin: auto;
  line-height: 1.5;
  max-width: 690px;
`
const JobDetailContent: React.FC<Props> = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
)

export default JobDetailContent
