import BenefitParking from "../images/benefits/benefit-parking.svg"
import BenefitFood from "../images/benefits/benefit-food.svg"
import BenefitCoffe from "../images/benefits/benefit-coffee.svg"
import BenefitEquipment from "../images/benefits/benefit-equipment.svg"
import BenefitTelephone from "../images/benefits/benefit-telephone.svg"
import BenefitSickday from "../images/benefits/benefit-sickday.svg"
import BenefitSelfStudy from "../images/benefits/benefit-selfstudy.svg"
import BenefitMealTicket from "../images/benefits/benefit-meal-ticket.svg"
import BenefitEnglish from "../images/benefits/benefit-english.svg"
import BenefitMultisport from "../images/benefits/benefit-multisport.svg"
import BenefitQuietRoom from "../images/benefits/benefit-quiet-room.svg"

export const EN_BENEFITS = [
  {
    heading: "Full fridge",
    src: BenefitFood,
    text: 'You\'ll never go hungry again. But watch out, "Piškotíky" are like hot cakes...',
  },
  {
    heading: "Great coffee",
    src: BenefitCoffe,
    text: "Meeting at the coffee machine is our ritual. Espresso and filtered coffee lovers alike.",
  },
  {
    heading: "Amenities",
    src: BenefitEquipment,
    text: "Expect two monitors, a proper chair, a notebook and whatever else you need for work.",
  },
  {
    heading: "Parking",
    src: BenefitParking,
    text: "Park in our lot just steps away from your office chair.",
  },
  {
    heading: "Sickdays",
    src: BenefitSickday,
    text: "You won't come to work and we won't ask why.",
  },
  {
    heading: "Mobile plan",
    src: BenefitTelephone,
    text: "After a year, a smartphone with unlimited usage plan. Or right away if you need it for work.",
  },
  {
    heading: "Self-education",
    src: BenefitSelfStudy,
    text: "Eight hours a month of work time for self-education.",
  },
  {
    heading: "Meal allowance",
    src: BenefitMealTicket,
    text: "No wasted paper, straight into your account every month.",
  },
  {
    heading: "MultiSport card",
    src: BenefitMultisport,
    text: "Do sports up to 365 days a year, we'll contribute half.",
  },
  {
    heading: "Czech",
    src: BenefitEnglish,
    text: "Find a school or tutor and we'll contribute half.",
  },
  {
    heading: "Quiet room",
    src: BenefitQuietRoom,
    text: "Use the quiet to concentrate and leave the noise at the door.",
  },
]
